(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof Imports === 'object' && typeof module === 'object')
		module.Imports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof Imports === 'object')
		Imports["feather"] = factory();
	else
		root["feather"] = factory();
})(typeof self !== 'undefined' ? self : this, function() {
return 